import React, { useState } from "react";
import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import UnorderedList from "~/components/List/Unordered";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);
  return (
    <LessonLayout
      current_step={1}
      lesson={"Lesson 5"}
      color={"hs"}
      total_count={7}
      module_title="Putting Your Rubric to Work"
    >
      <ContentWrapper>
        <H2 underline>Step 1</H2>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={true}
            setFullScreen={setStudentFullScreen}
          >
            <p>When you started this unit, you were given the challenge of developing a rubric that could help other people evaluate health-related information. Before you give it to someone else, you should test it out.</p>
            <p>To begin testing your rubric, take another look at the 3 websites about CBD that you have been evaluating.</p>

            <div className="flex flex-wrap flex-col md:flex-row md:flex-nowrap place-content-between">
              <Button
                action="secondary"
                className="md:m-2"
                color="indigo"
                href="/article/cbd/cbd-now"
                icon="external"
                title="CBD website 1"
              >
                CBD website 1
              </Button>
              <Button
                action="secondary"
                className="md:m-2 mt-2"
                color="indigo"
                href="/article/cbd/cbd-truth"
                icon="external"
                title="CBD website 2"
              >
                CBD website 2
              </Button>
              <Button
                action="secondary"
                className="md:m-2 mt-2"
                color="indigo"
                href="/article/cbd/cansativa-organics"
                icon="external"
                title="CBD website 3"
              >
                CBD website 3
              </Button>
            </div>

            <p>Do you want to make any changes to the scores on your rubric?</p>
            <p>When you are happy with your scores, add up your final score for each one. How do your scores compare with your ideas when you first looked at these sites in Lesson 1?</p>
            <p>Do you feel that you are better able to answer the original question about CBD, “Would medical professionals think CBD is a good way for people to deal with long-term pain?” Why or why not?</p>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={true}
            setFullScreen={setTeacherFullScreen}
          >
            <p>Introduce students to this lesson by informing them that they will be making any final changes or additions to their rubric before testing it out on new information. Give them 5–10 minutes to review the CBD websites using their rubrics and to make any desired changes to either the rubric or their scores. After students complete this review, discuss the following questions with the class:</p>
            <UnorderedList>
              <li>How do your scores compare with your ideas when you first looked at these sites in Lesson 1?</li>
              <li>Based on the information in these websites, do you feel that you are better able to answer the original question about CBD, “Would medical professionals think CBD is a good way for people to deal with long-term pain?” Why or why not?</li>
            </UnorderedList>

            <p>You could also ask students whether they feel any of the websites really give them enough information to be truly comfortable answering the question. Students are likely to have noticed inconsistencies among the three CBD websites. None of the websites is well documented which could reasonably lead students to having doubts about the information. You could emphasize the fact that there is currently only one approved use for CBD (relating to a rare form of epilepsy). None of the more commonly advertised uses of CBD is well substantiated scientifically.</p>
            <p>If it would be helpful for your students, this could be an opportunity to emphasize the importance of the question that is being answered using the rubric. The question that students are answering relates to how medical professionals view CBD. The answer could be very different if the question were different (e.g., “Do you think people should use CBD?”).</p>


            <Button
              action="secondary"
              className="m-4"
              color="indigo"
              href="/files/hs/lesson_5/cbd_website_summary_hs.pdf"
              icon="download"
              title="CBD Websites Comparison and Analysis"
            >
              CBD websites comparison and analysis
            </Button>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

